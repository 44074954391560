import axios from './http'

//获取信息
export const getInfo = params => {
  return axios.post('/user/info', {params: params});
};

//退出接口
export const logout = params => {
    return axios.post('/user/logout', params)
};

//获取账户
export const getAccounts = params => {
    return axios.get('/account/index', {params: params});
};

//获取账户详情
export  const getAccountDetail = params => {
    return axios.get('/account/detail', {params: params});
};

//创建账户
export const createAccount = params => {
    return axios.post('/account/create', params);
};

//编辑账户
export const updateAccount = params => {
    return axios.post('/account/update', params);
};

//删除账户
export const deleteAccount = params => {
    return axios.post('/account/delete', params);
};

//统计数据
export const analysisData = params => {
    return axios.get('/data/index', {params: params})
};

//资产概况
export const generalData = params => {
    return axios.get('/data/general', {params: params})
};

//原始数据
export const originalData = params => {
    return axios.get('/original/index', {params: params})
};

//交易明细
export const tradeDetail = params => {
    return axios.get('data/trade-detail', {params: params});
};

//查看笔记
export const apiGetNotes = params => {
    return axios.get('/notes/index', {params: params});
};

//创建笔记
export const apiSaveNotes = params => {
    return axios.post('/notes/save', params);
};

// 获取期货公司列表
export const apiCompany = params => {
    return axios.get('/account/company', {params: params});
};

// 获取协议内容
export const apiGetAgreement = params => {
    return axios.get('/user/agreement', params);
};

// 同意协议
export const apiAgreement = params => {
    return axios.post('/user/agreement', {params: params});
};

// 账户排序
export const apiAccountSort = params => {
    return axios.post('/account/sort',params);
};

// 原始数据-持仓明细
export const apiTakeDetail = params => {
    return axios.get('/original/take-detail',{params: params});
};

// 原始数据-持仓汇总
export const apiTakeTotal = params => {
    return axios.get('/original/take-total',{params: params});
};


// 单边敞口
export const apiExposure = params => {
    return axios.get('/data/index',{params: params});
};

//原始数据-成交明细
export const originalTradeDetailData = params => {
    return axios.get('/original/trade-detail', {params: params})
};

// k线
export const apiKline = params => {
    return axios.get('/market/kline', {params: params})
};

// 合约
export const apiContract = params => {
    return axios.get('/market/search-contract', {params: params})
};
// 点位/point/bs?a_id=975&period=minute&contract=a2401&b_date=&e_date=
export const apiKlinePoint = params => {
    return axios.get('/point/bs', {params: params})
};