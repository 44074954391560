import axios from '../utils/http'

//测试登录
export const apiSignin=(params)=>{
  return axios.post('/signin', params)
}

// 获取二维码token
export const apiGenWeappLoginToken=()=>{
  const time = new Date().getTime()
  return axios.get('/genWeappLoginToken', {params:time})
}
// 登录轮循
export const apiQueryWeappQrcodeStatus=(params)=>{
  const time = new Date().getTime()
  return axios.get('/queryWeappQrcodeStatus', {params: {...params, time}})
}
// 获取用户信息
export const apiUserInfo=()=>{
  const time = new Date().getTime()
  return axios.get('/user/info',{params:time})
}

// 获取账户列表
export const apiAccounts=()=>{
  const time = new Date().getTime()
  return axios.get('/accounts',{params:time})
}

// 查询账户信息
export const apiGetAccount=(id)=>{
  const time = new Date().getTime()
  return axios.get(`/accounts/${id}`,{params:time})
}

// 新增账户
export const apiCreateAccount=(params)=>{
  return axios.post(`/accounts`, params)
}

// 修改账户
export const apiUpdateAccount=(id, params)=>{
  return axios.put(`/accounts/${id}`, {params})
}

// 删除账户
export const apiDeleteAccount=(id)=>{
  return axios.delete(`/accounts/${id}`)
}
