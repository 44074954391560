<template>
    <div class="account">
        <div class="header">
            <div class="container clearfix">
                <div class="logo-box">
                    <router-link to="/"></router-link>
                    <!--          <span> · 交易分析神器</span>-->
                </div>
                <div class="right-btn">
                    <router-link to="/" title="首页"><i class="el-icon-s-home"></i></router-link>
                   <UserMenu />
                </div>
            </div>
        </div>
        <div class="account-main container">
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-card class="account-box mt20">
                        <div slot="header" class="clearfix">
                            <span>期货监控中心账户管理</span>
                        </div>
                        <el-row :gutter="20" class="accounts">
<!--                            <draggable v-model="accounts" @update="datadragEnd">-->
                                <el-col :span="6" v-for="item in accounts" :key="item.id">
                                    <el-card class="mt20">
                                        <div slot="header" class="clearfix">
                                            <span>{{ item.account }}</span>
                                            <div class="account-btns">
                                                <!--<el-tooltip class="item" effect="dark" content="更新数据" placement="top">
                                                                        <el-button type="text" @click="updateData(item.id)"><i
                                                                                class="el-icon-refresh"></i></el-button>
                                                                    </el-tooltip>-->
                                                <el-tooltip
                                                        class="item"
                                                        effect="dark"
                                                        content="编辑账户"
                                                        placement="top"
                                                        :open-delay="500"
                                                >
                                                    <el-button type="text" @click="editAccount(item.id)"
                                                    ><i class="el-icon-edit"></i
                                                    ></el-button>
                                                </el-tooltip>
                                                <!--                        <el-button type="text"><i class="el-icon-unlock"></i></el-button>-->
                                                <el-tooltip class="item" effect="dark" content="删除账户" placement="top"
                                                            :open-delay="500" v-show="item.last_data_at === 0">
                                                    <el-button type="text" @click="openDialog(item.id)"><i
                                                            class="el-icon-delete"></i></el-button>
                                                </el-tooltip>
                                            </div>
                                        </div>
                                        <p :title="item.nickname">
                                            账户昵称：<span>{{ item.nickname }}</span>
                                        </p>
                                        <p>
                                            更新状态：
                                            <span v-if="item.status === 1" style="color: green">已更新</span>
                                            <span v-if="item.status === 0">等待更新</span>
                                            <span v-if="item.status === -1" style="color: red">账号或密码错误</span>
                                            <span v-if="item.status === 2" style="color: blue">更新中...</span>
                                        </p>
                                        <p>
                                            最新数据：<span >{{item.last_data_at_format || '暂无'}}</span>
                                        </p>
                                        <p>
                                            更新时间：<span >{{item.last_data_at_format || '暂无'}}</span>
                                        </p>
                                        <p :title="item.note">
                                            账号备注：<span v-if="item.note">{{ item.note }}</span>
                                            <span v-if="!item.note">无</span>
                                        </p>
                                    </el-card>
                                </el-col>
<!--                            </draggable>-->
                            <el-col :span="6">
                                <el-card class="add-account mt20" @click.native="addAccount">
                                    <i class="el-icon-plus"></i>
                                </el-card>
                            </el-col>
                        </el-row>
                        <!--                        <div class="card-footer">-->
                        <!--                            <el-button type="primary" @click="$router.push({name: 'home'})">返回</el-button>-->
                        <!--                        </div>-->
                    </el-card>
                </el-col>
            </el-row>
        </div>

        <el-dialog
                title="添加期货监控中心账户"
                width="800px"
                :visible.sync="dialogFormVisible"
                :close-on-click-modal="false"
                class="create-account-dialog"
        >
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item
                        label="账户昵称"
                        :label-width="formLabelWidth"
                        item-id="nickname"
                        prop="nickname"
                        :error="errors.nickname"
                >
                    <el-popover
                            ref="nicknamePopover"
                            placement="right"
                            width="200"
                            trigger="focus"
                            content="美名宜简洁响亮，限2-10个字符以内"
                    >
                    </el-popover>
                    <el-input
                            v-popover:nicknamePopover
                            v-model="form.nickname"
                            autocomplete="off"
                            placeholder="请输入账户昵称"
                    ></el-input>
                </el-form-item>

                <el-form-item
                        label="监控中心账号"
                        :label-width="formLabelWidth"
                        item-id="account_code"
                        prop="account_code"
                        :error="errors.account_code"
                >
                    <el-popover
                            ref="accountPopover"
                            placement="right"
                            width="200"
                            trigger="focus"
                    >
                        <p>
              <span style="color: #aa0000">此账号非交易账号</span
              >，默认在开户时与交易账户同时开立并短信通知，如遗失可咨询所在期货公司寻回，<a
                                href="https://www.zghps.cn/help/phone"
                                target="_blank"
                                style="color: #2a7cd4"
                        >点击查看各期货公司联系方式</a
                        >
                        </p>
                        <p style="text-align: center; margin-top: 20px">
                            如遇问题，欢迎联系客服
                        </p>
                        <p style="text-align: center">
                            <img src="../assets/wxqrcode.jpg" alt=""/>
                        </p>
                        <p style="text-align: center">官方微信</p>
                        <p style="text-align: center; margin-top: 10px">
                            客服电话：023-67460894
                        </p>
                    </el-popover>
                    <el-input
                            v-popover:accountPopover
                            v-model="form.account_code"
                            autocomplete="off"
                            placeholder="请输入保证金监控中心账号"
                    ></el-input>
                </el-form-item>
                <el-form-item
                        label="监控中心密码"
                        :label-width="formLabelWidth"
                        item-id="password"
                        prop="password"
                        :error="errors.password"
                >
                    <el-popover
                            ref="passwordPopover"
                            placement="right"
                            width="200"
                            trigger="focus"
                    >
                        <p>
              <span style="color: #aa0000">此密码非交易密码</span
              >，初始密码务必前往
                            <a
                                    href="https://investorservice.cfmmc.com/"
                                    style="text-decoration: underline"
                                    target="_blank"
                            >保证金监控中心</a
                            >
                            修改，修改后方可使用
                        </p>
                        <p style="text-align: center; margin-top: 20px">
                            如遇问题，欢迎联系客服
                        </p>
                        <p style="text-align: center">
                            <img src="../assets/wxqrcode.jpg" alt=""/>
                        </p>
                        <p style="text-align: center">官方微信</p>
                        <p style="text-align: center; margin-top: 10px">
                            客服电话：023-67460894
                        </p>
                    </el-popover>
                    <el-input
                            v-popover:passwordPopover
                            type="text"
                            v-model="form.password"
                            autocomplete="off"
                            placeholder="请输入保证金监控中心密码"
                    ></el-input>
                </el-form-item>
                <el-form-item
                        label="账号备注"
                        :label-width="formLabelWidth"
                        item-id="note"
                        prop="note"
                        :error="errors.note"
                >
                    <el-input
                            type="textarea"
                            v-model="form.note"
                            autocomplete="off"
                            placeholder=""
                    ></el-input>
                </el-form-item>
                <!--<el-form-item label="是否参赛" :label-width="formLabelWidth" class="campaign">
                            <el-switch v-model="form.campaign" activeText="参赛" inactiveText="不参赛"></el-switch>
                        </el-form-item>-->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitForm('form')">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog
                title="修改期货监控中心账户"
                width="800px"
                :visible.sync="editDialogFormVisible"
                :close-on-click-modal="false"
                class="create-account-dialog"
        >
            <el-form :model="editForm" :rules="editRules" ref="editForm">
                <el-form-item
                        label="账户昵称"
                        :label-width="formLabelWidth"
                        item-id="nickname"
                        prop="nickname"
                        :error="editErrors.nickname"
                >
                    <el-popover
                            ref="editNicknamePopover"
                            placement="right"
                            width="200"
                            trigger="focus"
                            content="美名宜简洁响亮，限2-10个字符以内"
                    >
                    </el-popover>
                    <el-input
                            v-popover:editNicknamePopover
                            v-model="editForm.nickname"
                            autocomplete="off"
                            placeholder="请输入账户昵称"
                    ></el-input>
                </el-form-item>

                <el-form-item
                        label="监控中心账号"
                        :label-width="formLabelWidth"
                        item-id="account_code"
                        prop="account_code"
                        :error="editErrors.account_code"
                >
                    <el-popover
                            ref="editAccountPopover"
                            placement="right"
                            width="200"
                            trigger="focus"
                    >
                        <p>
              <span style="color: #aa0000">此账号非交易账号</span
              >，默认在开户时与交易账户同时开立并短信通知，如遗失可咨询所在期货公司寻回，<a
                                href="//www.zghps.cn/help/phone"
                                target="_blank"
                                style="color: #2a7cd4"
                        >点击查看各期货公司联系方式</a
                        >
                        </p>
                        <p style="text-align: center; margin-top: 20px">
                            如遇问题，欢迎联系客服
                        </p>
                        <p style="text-align: center">
                            <img src="../assets/wxqrcode.jpg" alt=""/>
                        </p>
                        <p style="text-align: center">官方微信</p>
                        <p style="text-align: center; margin-top: 10px">
                            客服电话：023-67460894
                        </p>
                    </el-popover>
                    <el-input
                            v-popover:editAccountPopover
                            v-model="editForm.account_code"
                            autocomplete="off"
                            placeholder="请输入保证金监控中心账号"
                            :disabled="!allow_account_edit"
                    ></el-input>
                </el-form-item>
                <el-form-item
                        label="监控中心密码"
                        :label-width="formLabelWidth"
                        item-id="password"
                        prop="password"
                        :error="editErrors.password"
                >
                    <el-popover
                            ref="editPasswordPopover"
                            placement="right"
                            width="200"
                            trigger="focus"
                    >
                        <p>
              <span style="color: #aa0000">此密码非交易密码</span
              >，初始密码务必前往
                            <a
                                    href="https://investorservice.cfmmc.com/"
                                    style="text-decoration: underline"
                                    target="_blank"
                            >保证金监控中心</a
                            >
                            修改，修改后方可使用
                        </p>
                        <p style="text-align: center; margin-top: 20px">
                            如遇问题，欢迎联系客服
                        </p>
                        <p style="text-align: center">
                            <img src="../assets/wxqrcode.jpg" alt=""/>
                        </p>
                        <p style="text-align: center">官方微信</p>
                        <p style="text-align: center; margin-top: 10px">
                            客服电话：023-67460894
                        </p>
                    </el-popover>
                    <el-input
                            v-popover:editPasswordPopover
                            type="text"
                            v-model="editForm.password"
                            autocomplete="off"
                            placeholder="请输入保证金监控中心密码"
                    ></el-input>
                </el-form-item>
                <el-form-item
                        label="账号备注"
                        :label-width="formLabelWidth"
                        item-id="note"
                        prop="note"
                        :error="editErrors.note"
                >
                    <el-input
                            type="textarea"
                            v-model="editForm.note"
                            autocomplete="off"
                            placeholder=""
                    ></el-input>
                </el-form-item>
                <!--<el-form-item label="是否参赛" :label-width="formLabelWidth" class="campaign" v-show="!editForm.campaigned">
                            <el-switch v-model="editForm.campaign" activeText="参赛" inactiveText="不参赛"></el-switch>
                        </el-form-item>-->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editDialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="editSubmitForm('editForm')">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog
                title="注意"
                width="500px"
                :visible.sync="deleteAccountDialog"
                :close-on-click-modal="false"
                class="create-account-dialog"
        >
            <p>删除账户不可恢复，确定删除？</p>
            <div slot="footer" class="dialog-footer">
                <el-button @click="deleteAccountDialog = false">取 消</el-button>
                <el-button type="primary" @click="deleteAccount">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
  // import draggable from "vuedraggable";
  import UserMenu from "../components/UserMenu";
  import {
    apiAccountSort,
  } from "../utils/api";
  import {apiAccounts, apiCreateAccount, apiUpdateAccount, apiGetAccount, apiDeleteAccount} from '../api/user'

  export default {
    name: "Account",
    components: {
      // draggable,
      UserMenu
    },
    data () {
      return {
        dialogFormVisible: false,
        editDialogFormVisible: false,
        deleteAccountDialog: false,
        deleteId: null,
        form: {
          nickname: "",
          account_code: "",
          password: "",
          note: "",
          // campaign: true,
        },
        rules: {
          nickname: [
            {required: true, message: "请输入账户昵称", trigger: "blur"},
          ],
          account_code: [
            {
              required: true,
              message: "请输入保证金监控中心账号",
              trigger: "blur",
            },
          ],
          password: [
            {
              required: true,
              message: "请输入保证金监控中心密码",
              trigger: "blur",
            },
          ],
        },
        errors: {},
        editForm: {
          id: "",
          nickname: "",
          account_code: "",
          password: "",
          note: "",
          // campaign: true,
          // campaigned: true,
        },
        allow_account_edit: false,
        editRules: {
          nickname: [
            {required: true, message: "请输入账户昵称", trigger: "blur"},
          ],
          account_code: [
            {
              required: true,
              message: "请输入保证金监控中心账号",
              trigger: "blur",
            },
          ],
          password: [
            {
              required: true,
              message: "请输入保证金监控中心密码",
              trigger: "blur",
            },
          ],
        },
        editErrors: {},
        formLabelWidth: "120px",
      };
    },
    computed: {
      curAccountId: function () {
        return this.$store.state.curAccountId;
      },
      accounts: function () {
        return this.$store.state.allAccounts;
      },
    },
    methods: {
      //拖动中与拖动结束
      async datadragEnd () {
        let params = []
        let accountList = []
        for (let item of this.accounts) {
          params.push(parseInt(item.id))
          accountList.push({id: item.id, nickname: item.nickname, account: item.account})
        }

        apiAccountSort({ids: params})
        this.$store.commit("SET_ACCOUNTS", accountList);
        if (accountList.length > 0) {
          this.$store.commit(
            "SET_CUR_ACCOUNT_ID",
            [accountList[0].id]
          );
        }
      },
      async getAccounts () {
        let res = await apiAccounts()
        if (res.data.code === 0 && res.data.data) {
          const accounts = res.data.data
          if (accounts && accounts.length > 0) {
            let effectiveAccounts = []
            let AllAccounts = accounts.map(item => {
              item.name = item.nickname + '(' + item.account_code + ')';
              if (item.last_data_at !== 0 && item.status === 1) {
                item.last_data_at_format = this.$moment(new Date(item.last_data_at * 1000)).format('YYYY-MM-DD')
                effectiveAccounts.push(item)
              }
              return item
            })
            if (effectiveAccounts.length > 0) {
              this.$store.commit('SET_ACCOUNTS', effectiveAccounts)
              this.$store.commit('SET_CUR_ACCOUNT_ID', [effectiveAccounts[0].id])
            }
            this.$store.commit('SET_ALL_ACCOUNTS', AllAccounts)
            this.$store.commit('SET_ANALYSIS_TYPE', 'single')
          }
        } else {
          this.goBack()
        }
      },
      addAccount () {
        this.dialogFormVisible = true;
      },
      editAccount (id) {
        id = parseInt(id);
        apiGetAccount(id).then((response) => {
          if (response.data.code === 0) {
            let accountDetail = response.data.data;
            this.editForm.id = id;
            this.editForm.nickname = accountDetail.nickname;
            this.editForm.account_code = accountDetail.account_code;
            this.editForm.password = accountDetail.password;
            this.editForm.note = accountDetail.note;
            // this.editForm.campaign = accountDetail.campaign;
            // this.editForm.campaigned = accountDetail.campaign;
            this.allow_account_edit = accountDetail.last_data_at === 0;

            this.editDialogFormVisible = true;
          } else {
            this.$message({
              message: "数据加载错误，请稍后再试！",
              type: "warning",
            });
          }
        });
      },
      submitForm (formName) {
        this.errors = {};
        this.$refs[formName].validate((valid) => {
          if (valid) {
            apiCreateAccount(this.form).then((response) => {
              if (response.data.code === 0) {
                this.$store.commit("SET_ACCOUNTS", response.data.data);
                this.dialogFormVisible = false;
                this.$alert(
                  "添加账号之后，系统将在当日20点左右开始获取数据，建议您次日查看分析数据。",
                  "账户添加成功",
                  {
                    confirmButtonText: "确定",
                  }
                );
                this.resetForm(formName);
                this.getAccounts();
              } else {
                this.errors = response.data.errors;
                this.$message({
                  message: "添加失败",
                  type: "error",
                });
              }
            });
          } else {
            return false;
          }
        });
      },
      editSubmitForm (formName) {
        this.editErrors = {};
        this.$refs[formName].validate((valid) => {
          if (valid) {
            apiUpdateAccount(this.editForm.id, this.editForm).then((response) => {
              if (response.data.code === 0) {
                this.$store.commit("SET_ACCOUNTS", response.data.data);
                this.editDialogFormVisible = false;
                this.$alert(
                  "系统将在每晚20点继续为您更新数据。",
                  "账户更新成功",
                  {
                    confirmButtonText: "确定",
                  }
                );
                this.getAccounts();
              } else {
                this.editErrors = response.data.errors;
                this.$message({
                  message: "更新失败",
                  type: "error",
                });
              }
            });
          } else {
            return false;
          }
        });
      },
      openDialog (id) {
        this.deleteId = id
        this.deleteAccountDialog = true;
      },
      async deleteAccount () {
        let res = await apiDeleteAccount(this.deleteId)
        if (res.data.code === 0 && res.data.data) {
          await this.getAccounts()
          this.deleteAccountDialog = false
          this.$message({
            message: '删除成功',
            type: 'success'
          });
        }
      },
      resetForm (formName) {
        this.$refs[formName].resetFields();
      },
    },
    mounted () {
      this.getAccounts();
    },
  };
</script>

<style lang="scss">
    .account {
        text-align: left;

        .mt20 {
            margin-top: 20px;
        }

        .header {
            .logo-box {
                float: left;
                text-align: left;
                padding-top: 9px;

                a {
                    display: inline-block;
                    width: 216px;
                    height: 38px;
                    background-image: url("../assets/qiweihu_logo.png");
                    background-size: 216px 38px;
                    float: left;
                }

                span {
                    line-height: 45px;
                    margin-left: 10px;
                    font-size: 14px;
                }
            }

            .right-btn {
                float: right;
                display: flex;
                align-items: center;
                i {
                    font-size: 26px;
                    color: #333333;
                    line-height: 60px;
                    margin-right: 20px;
                }
            }
        }

        .account-main {
            .account-box {
                border: 0;

                & > .el-card__header {
                    background: #1777FF;
                    color: #ffffff;
                }

                & > .el-card__body {
                    padding-top: 0;
                }

                .card-footer {
                    padding-top: 40px;
                    text-align: right;
                }
            }

            .accounts {
                .el-card {
                    height: 183px;

                    &:hover {
                        box-shadow: 0 2px 12px 0 rgba(255, 223, 215);
                        border-color: rgba(255, 223, 215);
                        cursor: pointer;

                        &.add-account {
                            color: #dd593b;
                        }
                    }

                    &.add-account {
                        line-height: 173px;
                        font-size: 50px;
                        color: #999999;
                        text-align: center;

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    .el-card__header {
                        padding: 5px 10px;
                        background: #f5f6f7;

                        .clearfix {
                            text-align: left;
                            line-height: 29px;

                            & > span {
                                color: #333333;
                            }

                            .account-btns {
                                float: right;

                                .el-button {
                                    padding: 3px 0;

                                    i {
                                        font-size: 20px;
                                        color: #999999;
                                    }
                                }
                            }
                        }
                    }

                    .el-card__body {
                        padding: 5px 10px;

                        p {
                            text-align: left;
                            font-size: 14px;
                            margin: 5px 0;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            color: #000000;

                            span {
                                color: #666;
                            }
                        }
                    }
                }
            }

            .create-account-dialog {
                .el-form-item {
                    &.is-error {
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }

    .el-popper {
        p {
            margin: 0;
        }

        a {
            color: #2a7cd4;
        }

        img {
            width: 150px;
            height: 150px;
        }
    }
    .footer {
        width: 100%;
        position: fixed;
        bottom: 0;
        z-index: 1000;
    }
</style>
