<template>
    <el-dropdown @command="handleCommand" placement="bottom">
                        <span class="el-dropdown-link text">
                          <i class="el-icon-user-solid"></i>
                        </span>
        <el-dropdown-menu slot="dropdown" class="account-menu">
            <el-dropdown-item icon="el-icon-phone" disabled>{{ user.mobile }}</el-dropdown-item>
            <el-dropdown-item
                    icon="el-icon-switch-button"
                    command="logout"
            >退出登录
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
  import {mapState} from "vuex";
  export default {
    name: "UserMenu",
    computed: {
      ...mapState({
        user: (state) => state.user
      })
    },
    methods: {
      handleCommand (command) {
        if (command === "logout") {
          this.$message({
            message: "退出成功",
            type: "success"
          })
          localStorage.clear()
          this.$store.commit('SET_TOKEN', '')
          this.$store.commit('SET_TOKEN', '')
          this.$store.commit('SET_ACCOUNTS', [])
          this.$store.commit('SET_CUR_ACCOUNT_ID', [])
          this.$store.commit('SET_ALL_ACCOUNTS', [])
          this.$store.commit('SET_ANALYSIS_TYPE', 'single')
          this.$store.commit('SET_USER', null)
          this.$router.replace('/login')
        }
      },
    }
  }
</script>

<style scoped>

</style>
